var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrbTypes } from './OrbTypes';
import { Orb } from './Orb';
import * as TextCreation from '../services/creation/TextCreation';
import * as SystemAPI from '../services/SystemAPI';
/**
 * The OrbFactory is responsible for:
 * - Defining a collection of known orb configurations (`orbConfigs`).
 * - Creating orbs of various types based on a given configuration.
 * - Fetching and constructing orbs dynamically from various APIs.
 */
export class OrbFactory {
    /**
     * **Initialize and fetch all orbs**
     * - This should be called once at startup.
     * - It populates `cachedOrbs` so that orbs do not need to be refetched.
     */
    static initializeOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            if (OrbFactory.cachedOrbs.length > 0)
                return;
            try {
                console.log("Initializing Orbs...");
                const fetchOrbFunctions = [
                    OrbFactory.fetchLanguageOrbs,
                    OrbFactory.fetchGitHubOrbs,
                    OrbFactory.fetchDockerOrbs,
                    OrbFactory.fetchKubernetesPodOrbs,
                    OrbFactory.fetchKubernetesNodeOrbs,
                    OrbFactory.fetchDesmosOrbs,
                    OrbFactory.fetchOrbTypes,
                    OrbFactory.fetchContainerEndpointOrbs
                ];
                // Fetch all orbs in parallel
                const results = yield Promise.all(fetchOrbFunctions.map(fn => fn()));
                // Flatten and store in cache
                OrbFactory.cachedOrbs = results.flat();
                console.log(`Orbs initialized: ${OrbFactory.cachedOrbs.length}`);
            }
            catch (error) {
                console.error("Error initializing orbs:", error);
            }
        });
    }
    /**
     * **Get Cached Orbs**
     * - Returns the previously fetched orbs without making another API call.
     * - If orbs have not been initialized, warns and returns an empty array.
     * @returns An array of cached `Orb` instances.
     */
    static getCachedOrbs() {
        if (OrbFactory.cachedOrbs.length === 0) {
            console.warn("Orbs have not been initialized yet! Call `initializeOrbs()` first.");
            return [];
        }
        const validOrbs = OrbFactory.cachedOrbs.filter(orb => orb && orb.styleConfig);
        if (validOrbs.length !== OrbFactory.cachedOrbs.length) {
            console.warn(`Some orbs were invalid. Filtered from ${OrbFactory.cachedOrbs.length} → ${validOrbs.length}`);
        }
        return validOrbs;
    }
    /**
     * **Create a new orb based on type**
     * - Uses a predefined style configuration from `orbConfigs`
     * - Assigns optional JSON metadata and a text label
     * @param orbType - The type of orb to create (e.g., 'GITHUB_ORB')
     * @param labelText - Optional label to display on the orb
     * @param jsonData - Optional metadata to store in the orb
     * @returns A new `Orb` instance
     */
    static createOrbByType(orbType, labelText, jsonData = {}) {
        // ✅ Clone the config before modifying it - Not doing this resulted in a bug where only one orb had text at a time
        const config = Object.assign({}, OrbTypes[orbType]);
        if (!config) {
            console.error(`Invalid orb type: "${orbType}"`, { orbType, labelText, jsonData });
            throw new Error(`OrbFactory.createOrbByType: Invalid orb type "${orbType}"`);
        }
        if (labelText) {
            config.text = TextCreation.createTextSprite(labelText, 3, undefined, 10, undefined, '#f0f0f0', '#f0f0f0');
        }
        const newOrb = new Orb(config, jsonData);
        // 🚨 Debugging: Ensure orbs have valid `styleConfig`
        if (!newOrb.styleConfig) {
            console.error("Created orb is missing styleConfig!", newOrb);
            throw new Error(`OrbFactory.createOrbByType: Missing styleConfig for "${orbType}"`);
        }
        return newOrb;
    }
    /**
     * **Shared Function for Creating Orbs from API Data**
     * - Converts raw API data into structured orbs.
     * - Each API should pass a custom `parseFunction()` that extracts `orbType`, `label`, and `data`.
     * @param rawData - The unparsed API response array
     * @param parseFunction - A function that extracts necessary orb details
     * @returns An array of newly created orbs
     */
    static createOrbsFromData(rawData, parseFunction) {
        return rawData.map(item => {
            const { orbType, label, data } = parseFunction(item);
            return this.createOrbByType(orbType, label, data);
        });
    }
    /**
     * **Fetch and create orbs from the GitHub API**
     * @returns An array of `GITHUB_ORB` instances
     */
    static fetchGitHubOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const githubData = yield SystemAPI.getGitHubRepos();
                return OrbFactory.createOrbsFromData(githubData, (repo) => ({
                    orbType: 'GITHUB_ORB',
                    label: repo,
                    data: repo,
                }));
            }
            catch (error) {
                console.error('Error fetching GitHub orbs:', error);
                return [];
            }
        });
    }
    /**
     * **Fetch and create orbs from the Docker API**
     * @returns An array of `DOCKER_CONTAINER_ORB` instances
     */
    static fetchDockerOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const dockerData = yield SystemAPI.getDockerContainers();
                console.log(dockerData);
                return OrbFactory.createOrbsFromData(dockerData, (container) => ({
                    orbType: 'DOCKER_CONTAINER_ORB',
                    label: container.Name,
                    data: container,
                }));
            }
            catch (error) {
                console.error('Error fetching Docker orbs:', error);
                return [];
            }
        });
    }
    /**
     * **Fetch and create orbs from the Kubernetes API**
     * @returns An array of `KUBERNETES_ORB` instances
     */
    static fetchKubernetesPodOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const kubernetesData = yield SystemAPI.getKubernetesPods();
                return OrbFactory.createOrbsFromData(kubernetesData, (info) => ({
                    orbType: 'KUBERNETES_ORB',
                    label: info.Name,
                    data: info,
                }));
            }
            catch (error) {
                console.error('Error fetching Kubernetes node orbs:', error);
                return [];
            }
        });
    }
    /**
     * **Fetch and create orbs from the Kubernetes API**
     * @returns An array of `COMPUTER_ORB` instances
     */
    static fetchKubernetesNodeOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const kubernetesData = yield SystemAPI.getKubernetesNodes();
                return OrbFactory.createOrbsFromData(kubernetesData, (info) => ({
                    orbType: 'COMPUTER_ORB',
                    label: info.Name,
                    data: info,
                }));
            }
            catch (error) {
                console.error('Error fetching Kubernetes node orbs:', error);
                return [];
            }
        });
    }
    /**
     * **Fetch and create orbs from Desmos**
     * @returns An array of `DESMOS_ORB` instances
     */
    static fetchDesmosOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const desmosData = yield SystemAPI.getDesmosOrbs();
                //console.log("Raw Desmos Data:", desmosData);
                if (!Array.isArray(desmosData)) {
                    console.error("fetchDesmosOrbs: Expected an array but got:", desmosData);
                    return []; // Prevents passing a non-array to `map()`
                }
                return OrbFactory.createOrbsFromData(desmosData, (info) => ({
                    orbType: 'DESMOS_ORB',
                    label: info.title,
                    data: info,
                }));
            }
            catch (error) {
                console.error('Error fetching Kubernetes node orbs:', error);
                return [];
            }
        });
    }
    /**
 * **Fetch and create orbs from container endpoints**
 * @returns An array of `Orb` instances
 */
    static fetchContainerEndpointOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const endpointData = yield SystemAPI.getContainerEndpoints();
                //console.log("Raw Container Endpoint Data:", endpointData);
                if (!Array.isArray(endpointData)) {
                    console.error("fetchContainerEndpointOrbs: Expected an array but got:", endpointData);
                    return [];
                }
                // **Step 1: Preprocess data - Attach container metadata to each endpoint**
                const processedEndpoints = endpointData.flatMap(container => {
                    if (!container || !Array.isArray(container.Endpoints)) {
                        console.warn(`Skipping container (missing endpoints):`, container);
                        return [];
                    }
                    return container.Endpoints.map((endpoint) => (Object.assign(Object.assign({}, endpoint), { containerMetadata: {
                            ContainerID: container.ContainerID,
                            ContainerName: container.ContainerName,
                            Port: container.Port,
                            ApiSpecUrl: container.ApiSpecUrl
                        } })));
                });
                console.log("Processed Endpoints:", processedEndpoints);
                // **Step 2: Convert preprocessed data into orbs**
                return OrbFactory.createOrbsFromData(processedEndpoints, (endpoint) => ({
                    orbType: 'CONTAINER_ENDPOINT_ORB',
                    label: `${endpoint.containerMetadata.ContainerName}${endpoint.Path}`,
                    data: endpoint
                }));
            }
            catch (error) {
                console.error('Error fetching container endpoint orbs:', error);
                return [];
            }
        });
    }
    /**
     * **Fetch and create orbs from a language-based API**
     * - Determines orb type based on `language` field
     * @returns An array of orbs corresponding to different languages
     */
    static fetchLanguageOrbs() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const entries = yield SystemAPI.getLanguageOrbs();
                if (!Array.isArray(entries)) {
                    console.error("fetchLanguageOrbs: Expected an array but got:", entries);
                    return [];
                }
                // Extract `raw_json` field which already contains parsed JSON
                const parsedEntries = entries.map((entry) => entry.raw_json);
                return OrbFactory.createOrbsFromData(parsedEntries, (item) => {
                    var _a;
                    const language = ((_a = item.language) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || 'api';
                    const orbType = OrbFactory.mapLanguageToType(language);
                    const label = item.name || 'No name';
                    return { orbType, label, data: item };
                });
            }
            catch (error) {
                console.error('Error fetching language-based orbs:', error);
                return [];
            }
        });
    }
    /**
     * **Map programming languages to predefined orb types**
     * @param language - The programming language name
     * @returns The corresponding orb type string
     */
    static mapLanguageToType(language) {
        switch (language) {
            case 'typescript':
                return 'TYPESCRIPT_ORB';
            case 'javascript':
                return 'CLASSIC';
            case 'python':
                return 'PYTHON_ORB';
            case 'bash':
                return 'BASH_ORB';
            case 'java':
                return 'JAVA_ORB';
            case 'go':
                return 'GO_ORB';
            case 'rust':
                return 'RUST_ORB';
            case 'powershell':
                return 'POWERSHELL_ORB';
            case 'c':
            case 'c++':
                return 'CLASSIC'; // Or define custom for C/C++
            default:
                return 'API_ORB';
        }
    }
    // Read-only accessor method
    static getOrbConfig(orbType) {
        if (!orbType) {
            console.warn("getOrbConfig called with undefined orbType!");
            return undefined;
        }
        const config = OrbTypes[orbType];
        if (!config) {
            console.warn(`Orb config not found for type: ${orbType}`);
            return undefined;
        }
        return config;
    }
    /**
     * **Fetch and create orbs for all known orb types**
     * @returns An array of `Orb` instances
     */
    static fetchOrbTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const fullOrbTypes = OrbFactory.getFullOrbTypes();
                return OrbFactory.createOrbsFromData(fullOrbTypes, ({ name, config }) => ({
                    orbType: config,
                    label: name,
                    data: { type: name } // Store metadata about the orb type
                }));
            }
            catch (error) {
                console.error('Error fetching orb types:', error);
                return [];
            }
        });
    }
    static getFullOrbTypes() {
        return [
            { name: "Bash Orb", config: OrbTypes.BASH_ORB },
            { name: "Java Orb", config: OrbTypes.JAVA_ORB },
            { name: "Python Orb", config: OrbTypes.PYTHON_ORB },
            { name: "Powershell Orb", config: OrbTypes.POWERSHELL_ORB },
            { name: "Go Orb", config: OrbTypes.GO_ORB },
            { name: "Rust Orb", config: OrbTypes.RUST_ORB },
            { name: "JavaScript Orb", config: OrbTypes.JAVASCRIPT_ORB },
            { name: "TypeScript Orb", config: OrbTypes.TYPESCRIPT_ORB },
            { name: "Ansible Orb", config: OrbTypes.ANSIBLE_ORB },
            { name: "Math Orb", config: OrbTypes.MATH_ORB },
            { name: "Desmos Orb", config: OrbTypes.DESMOS_ORB },
            { name: "Github Orb", config: OrbTypes.GITHUB_ORB },
            { name: "Docker Container Orb", config: OrbTypes.DOCKER_CONTAINER_ORB },
            { name: "Endpoint Orb", config: OrbTypes.CONTAINER_ENDPOINT_ORB },
            { name: "Kubernetes Orb", config: OrbTypes.KUBERNETES_ORB },
            { name: "Computer Orb", config: OrbTypes.COMPUTER_ORB },
            { name: "Prompt Orb", config: OrbTypes.PROMPT_ORB },
            { name: "Prompt Response Orb", config: OrbTypes.PROMPT_RESPONSE_ORB },
            { name: "AI Agent Orb", config: OrbTypes.AI_AGENT_ORB },
            { name: "System Will Orb", config: OrbTypes.SYSTEM_WILL_ORB },
            { name: "Text Information Orb", config: OrbTypes.TEXT_INFO_ORB },
        ];
    }
}
/**
 * Global cache for storing fetched orbs.
 * This is initialized once and reused across the application.
 */
OrbFactory.cachedOrbs = [];
