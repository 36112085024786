var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrbManager } from './orbs/OrbManager';
import * as THREE from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import Stats from 'three/examples/jsm/libs/stats.module';
import * as ObjectCreation from './services/creation/ObjectCreation';
import ChatWindow from './services/chatbox/ChatWindow';
import * as TextCreation from './services/creation/TextCreation';
import Projectile from './models/Projectile';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import SceneManager from './services/scene/SceneManager';
import { OrbFactory } from './orbs/OrbFactory';
import { OrbTypes } from './orbs/OrbTypes';
import * as SystemAPI from './services/SystemAPI';
// Initialize SceneManager
const sceneManager = SceneManager.getInstance();
//ThreeJS objects
let scene;
let camera;
let renderer;
let controls;
let composer;
let renderPass;
let outlinePass;
//Movement switches
const movementInputDirection = new THREE.Vector3();
const velocity = new THREE.Vector3();
let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let moveUp = false;
let moveDown = false;
let hyperspeed = false;
let doWorldFloor = true;
//Movement coefficients
let movementDrag = 8;
let movementSpeed = 16.0;
//Camera
const FOV = 90;
const nearPlane = 1;
const farPlane = 20000;
//Time delta
let prevTime = performance.now();
//Stats
const stats = new Stats();
//Junk
const arenaSize = 700;
//Mouse stuff
const pointer = new THREE.Vector2();
const raycaster = new THREE.Raycaster();
var AbilityMode;
(function (AbilityMode) {
    AbilityMode[AbilityMode["CreateOrb"] = 0] = "CreateOrb";
    AbilityMode[AbilityMode["ShootProjectile"] = 1] = "ShootProjectile";
    AbilityMode[AbilityMode["DeleteObject"] = 2] = "DeleteObject";
    AbilityMode[AbilityMode["CreateRay"] = 3] = "CreateRay";
    AbilityMode[AbilityMode["InspectObject"] = 4] = "InspectObject";
    AbilityMode[AbilityMode["MoveObject"] = 5] = "MoveObject";
    AbilityMode[AbilityMode["EnterOrb"] = 6] = "EnterOrb";
    AbilityMode[AbilityMode["GenerateSphericalArrangement"] = 7] = "GenerateSphericalArrangement";
})(AbilityMode || (AbilityMode = {}));
let clickMode = AbilityMode.InspectObject;
let currentObject;
//Shooting stuff
const ballSpeed = 5;
const startSprayPosition = new THREE.Vector3(2000, 5000, -2000);
// Global variables
let projectiles = []; //todo remove
let orbManager = OrbManager.getInstance();
const chat = new ChatWindow();
let apiButtonOrbId = "";
function init() {
    return __awaiter(this, void 0, void 0, function* () {
        //CAMERA
        camera = new THREE.PerspectiveCamera(FOV, window.innerWidth / window.innerHeight, nearPlane, farPlane);
        camera.position.y++;
        //SCENE
        //scene = new THREE.Scene();
        scene = sceneManager.getScene();
        scene.background = new THREE.Color(0xefedfa); //0x7F87F8
        scene.fog = new THREE.Fog(0xffffff, 0, arenaSize * 30);
        //RENDERER
        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        //renderer.setPixelRatio(window.devicePixelRatio);    
        document.body.appendChild(renderer.domElement);
        //CONTROLS
        controls = new PointerLockControls(camera, document.body);
        clickEventControls();
        moveControls();
        //COMPOSER
        // Create the composer
        composer = new EffectComposer(renderer);
        // Create a render pass
        renderPass = new RenderPass(scene, camera);
        composer.addPass(renderPass);
        // Create an OutlinePass
        outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera);
        composer.addPass(outlinePass);
        //LIGHTING
        const skyColor = 0x9da4f5;
        const groundColor = 0x7F87F8;
        const intensity = 1.00;
        const light = new THREE.HemisphereLight(skyColor, 0x9da4f5, intensity);
        //var light2 = new THREE.PointLight(0xffffff);
        //light2.position.set(0,250,0);     
        scene.add(light);
        //EVENTS
        blocker();
        onWindowResize();
        //onDocumentMouseMove();    
        mouseWheelEvent();
        //ENVIRONMENT
        createWorldEnvironment();
        chat.addMessage("[Object Explorer Command Line]");
        createOrbButton();
        //await OrbFactory.initializeOrbs();
        //Stats
        initializeStats();
        //generateSystemOrbsTest();
        yield generateCodeOrbs();
    });
}
function initializeStats() {
    stats.showPanel(0); // Show FPS panel
    document.body.appendChild(stats.dom); // Add stats panel to DOM
    stats.dom.style.display = 'none'; // Hide the stats panel
    // Ensure fpsDisplay element is initialized
    const fpsElement = document.getElementById('fpsDisplay');
    if (fpsElement) {
        fpsElement.textContent = 'FPS: --'; // Default text
    }
    else {
        console.warn('fpsDisplay element not found in the DOM.');
    }
}
function onDocumentMouseMove() {
    document.addEventListener('mousemove', function (event) {
        if (controls.isLocked === true) {
            pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
            pointer.y = -(event.clientY / window.innerWidth) * 2 + 1;
            raycaster.setFromCamera(pointer, camera);
            const intersects = raycaster.intersectObjects(scene.children);
            if (intersects.length > 0) {
                currentObject = intersects[0].object;
                //console.log(currentObject);
            }
        }
    });
}
function mouseWheelEvent() {
    const numAbilities = Object.keys(AbilityMode).length / 2;
    const tooltext = document.getElementById('tooltext');
    // Variable to track whether a new wheel event has occurred
    let newWheelEvent = false;
    document.addEventListener('wheel', function (event) {
        if (controls.isLocked === true) {
            const delta = Math.sign(event.deltaY);
            if (delta == -1 && clickMode == 0)
                clickMode = numAbilities;
            clickMode = (clickMode + (delta * 1)) % numAbilities;
            // Update tooltext content based on clickMode
            tooltext.textContent = AbilityMode[clickMode] + ' (' + clickMode + ')';
            // Reset the opacity to 1 immediately on a new wheel event
            if (!newWheelEvent) {
                tooltext.style.opacity = '1';
                newWheelEvent = true;
            }
            // Set opacity to 0 after a delay if a new wheel event has occurred
            setTimeout(() => {
                if (newWheelEvent) {
                    tooltext.style.opacity = '0';
                    newWheelEvent = false; // Reset the flag
                }
            }, 2000); // Adjust delay before fading here (milliseconds)
            // Unselect inspected object
            outlinePass.selectedObjects = [];
        }
    });
}
function displayObjectJson() {
    const jsonData = document.getElementById('jsonData');
    jsonData.textContent = ''; // Clear existing content
    const currentObjectData = currentObject.toJSON();
    // Create a list element to hold the JSON keys and values
    const list = document.createElement('ul');
    // Iterate over the keys of the JSON data
    Object.keys(currentObjectData).forEach(key => {
        // Create a list item for the key and its value
        const listItem = document.createElement('li');
        // Add the key to the list item
        const keyItem = document.createElement('span');
        keyItem.textContent = key + ': ';
        listItem.appendChild(keyItem);
        // Add the value of the key to the list item
        const valueItem = document.createElement('span');
        valueItem.textContent = JSON.stringify(currentObjectData[key]);
        listItem.appendChild(valueItem);
        // Add the main list item to the main list
        list.appendChild(listItem);
    });
    // Append the main list to the container element
    jsonData.appendChild(list);
}
function warpToPoint() {
}
function createOrbButton() {
    var _a;
    //console.log(OrbFactory.OrbTypes.API_ORB);
    let apiOrbSphere = OrbFactory.createOrbByType('API_ORB', "Test API Button");
    apiOrbSphere.setPosition(new THREE.Vector3(-300, 150, -300));
    //console.log(`Orb button: ${apiOrbSphere.group.uuid}`)
    apiButtonOrbId = ((_a = apiOrbSphere.group) === null || _a === void 0 ? void 0 : _a.uuid) || '';
    apiOrbSphere.addToScene(scene);
}
function generateCodeOrbs() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const baseDirection = new THREE.Vector3(0, 0, 0);
            const sprayCoefficient = 1000;
            const timeGap = 500;
            // Array of fetch functions
            const fetchOrbFunctions = [
                OrbFactory.fetchLanguageOrbs,
                OrbFactory.fetchGitHubOrbs,
                OrbFactory.fetchDockerOrbs,
                OrbFactory.fetchKubernetesPodOrbs,
                OrbFactory.fetchKubernetesNodeOrbs,
                OrbFactory.fetchDesmosOrbs,
                OrbFactory.fetchOrbTypes,
                OrbFactory.fetchContainerEndpointOrbs
            ];
            // Fetch all orbs in parallel
            const fetchPromises = fetchOrbFunctions.map((fetchFunction) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const orbs = yield fetchFunction();
                    if (orbs && orbs.length > 0) {
                        return sprayOrbs(startSprayPosition, baseDirection, sprayCoefficient, orbs, timeGap);
                    }
                }
                catch (error) {
                    console.error("Error fetching orbs:", error);
                }
            }));
            // Wait for all spray operations to finish
            yield Promise.all(fetchPromises);
        }
        catch (error) {
            console.error("Error generating code orbs:", error);
        }
    });
}
function sprayOrbs(startPosition, baseDirection, sprayCoefficient, orbs, timeGap) {
    return __awaiter(this, void 0, void 0, function* () {
        //These are currently global variables
        //const orbManager = OrbManager.getInstance();
        //const scene = SceneManager.getInstance().getScene();
        for (const orb of orbs) {
            // Ensure the orb is created and positioned at the start location
            orb.setPosition(startPosition.clone());
            // Randomize the direction within the spray coefficient bounds
            const randomizedDirection = baseDirection.clone().add(new THREE.Vector3((Math.random() - 0.5) * sprayCoefficient, (Math.random() - 0.5) * sprayCoefficient, // Prevent excessive upward bias
            (Math.random() - 0.5) * sprayCoefficient)).normalize();
            // Apply initial velocity to the orb
            const speedMultiplier = ballSpeed * (Math.random() * 2.4 + 0.5); // Random speed
            const velocity = randomizedDirection.multiplyScalar(speedMultiplier);
            orb.addVelocity(velocity);
            // Add orb to scene and track in OrbManager    
            orbManager.addOrb(orb, scene);
            // Wait for the specified time gap before processing the next orb
            yield new Promise(resolve => setTimeout(resolve, timeGap));
        }
    });
}
// Call the API functions
function callAPI() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const texts = yield SystemAPI.getLanguageOrbs();
            chat.addMessage("Called API");
            chat.addMessage(texts);
            //await addText('New text entry');
            //await updateText(1, 'Updated text entry');
            //await deleteText(1);
        }
        catch (error) {
            console.error('Error in API operations:', error);
        }
    });
}
function createWorldEnvironment() {
    scene.add(TextCreation.createTextSprite("Object Explorer Prototype 10", 1, new THREE.Vector3(-20, 20, 20), undefined, undefined));
    scene.add(ObjectCreation.roundedBox());
    scene.add(ObjectCreation.createWorldFloor(arenaSize));
    scene.add(ObjectCreation.createWorldAxis(20000, 20000, 1000));
    /*
    ObjectCreation.createRandomBoxes(100, arenaSize)
      .forEach(box => {
        scene.add(box);
      });
    */
    ObjectCreation.createTransparentSpheres(300, arenaSize)
        .forEach(sphere => {
        scene.add(sphere);
    });
    const sphereConfig = OrbTypes.SPHERE_ORB;
    /*
    ObjectCreation.createOrbSphericalArrangement(
      1000,
      800,
      new THREE.Vector3(0, 1100, 1000),
      ObjectCreation.createOrb(
        sphereConfig.radius,
        sphereConfig.scaleFactor,
        new THREE.Vector3(),
        sphereConfig.colors,
        TextCreation.createTextSprite(
          "Test",
          2,
          undefined,
          undefined,
          undefined)
      )
    ).forEach(orb => {
      scene.add(orb);
    });
    */
    scene.add(ObjectCreation.createRoundedRectangle(15000, 10000, 10000, 500, 0x6699CC, new THREE.Vector3(-3000, 1000, 10000)));
    scene.add(ObjectCreation.createRoundedRectangle(9000, 7000, 7000, 500, 0x3320E3, new THREE.Vector3(10000, 3000, -2000)));
    scene.add(ObjectCreation.createDatabaseSymbol(230, 250, 0x157be8, 0x125280, 2000, -2000));
    // todo find a cleaner way to do this (monadic dot chaining?), then convert all other orb creations to that format
    let bOrb = OrbFactory.createOrbByType("BLACKHOLE", "");
    bOrb.setPosition(startSprayPosition);
    bOrb.addToScene(scene);
    //Test         
    const rainbowOrb = OrbTypes.RAINBOW_NEST;
    // TODO FIX
    const rainbowNestedSpheres = ObjectCreation.createOrb(rainbowOrb.radius, rainbowOrb.scaleFactor, new THREE.Vector3(-10000, 3000, 3000), rainbowOrb.colors, TextCreation.createTextSprite("This is a test", 2, undefined, undefined, undefined));
    scene.add(rainbowNestedSpheres);
    const rainbowNestedSpheres2 = ObjectCreation.createOrb(rainbowOrb.radius, rainbowOrb.scaleFactor, new THREE.Vector3(-10000, 2000, 3000), rainbowOrb.colors.reverse(), TextCreation.createTextSprite("This is a test too", 0.4, undefined, undefined, undefined));
    scene.add(rainbowNestedSpheres2);
}
//Test async
/*
async function createAndAddText() {
  try {
      let text: string = `long string of text here`;


      const textMesh1 = await TextCreation.createTextWithPanel(text, 10, new THREE.Vector3(1000, 600, -1000));
      scene.add(textMesh1);
  } catch (error) {
      console.error('Failed to create text:', error);
  }
}

async function createAndAddText2() {
  try {
      let text: string = `long text 2`;


      const textMesh1 = await TextCreation.createTextWithPanel(text, 10, new THREE.Vector3(1500, 700, -1000));
      scene.add(textMesh1);
  } catch (error) {
      console.error('Failed to create text:', error);
  }
}
*/
function blocker() {
    //Maybe add something to this to halt processing when blocked
    const blocker = document.getElementById('blocker');
    const instructions = document.getElementById('instructions');
    instructions.addEventListener('click', function () {
        controls.lock();
    });
    controls.addEventListener('lock', function () {
        instructions.style.display = 'none';
        blocker.style.display = 'none';
    });
    controls.addEventListener('unlock', function () {
        blocker.style.display = 'block';
        instructions.style.display = '';
    });
    scene.add(controls.getObject());
}
function applyOutlineToObject(object) {
    // Clear the previously selected objects
    outlinePass.selectedObjects = [];
    // Add the newly selected object to the array
    outlinePass.selectedObjects.push(object);
    // Update the outline effect parameters
    outlinePass.visibleEdgeColor.set('#ffffff'); // Set color to black
    outlinePass.hiddenEdgeColor.set('#ffffff'); // Set color to white
    outlinePass.edgeThickness = 2; // Set thickness of the dark outline
    outlinePass.edgeStrength = 100; // Set strength of the dark outline effect
}
//Depends on camera and scene
function getClosestObject() {
    // Create a raycaster to cast a ray from the camera's position in the direction it's facing
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2(); // Placeholder for mouse coordinates (not used in this example)
    // Set raycaster properties based on the camera's position and direction
    raycaster.setFromCamera(mouse, camera);
    // Perform raycasting to check for intersections with objects in the scene
    const intersects = raycaster.intersectObjects(scene.children, true); // Assuming 'scene' is your THREE.Scene
    if (intersects.length > 0) {
        return intersects[0].object;
    }
    else {
        return null;
    }
}
function setSelectedObject() {
    const intersectedObject = getClosestObject();
    // Check if any objects were intersected
    if (intersectedObject === null) {
        console.log('Nothing clicked');
        return;
    }
    //TODO make it so that outines always go to the end of an orb
    if (orbManager.getOrb(intersectedObject.parent.uuid) != undefined) {
    }
    currentObject = intersectedObject;
    if (currentObject.parent.uuid == apiButtonOrbId) {
        chat.addMessage("Button Clicked");
        callAPI();
    }
    let output = orbManager.handleOrbClick(currentObject);
    chat.addMessage(output);
}
function deleteSelectedObject() {
    var _a;
    const objectToDelete = getClosestObject();
    // Check if any objects were intersected
    if (objectToDelete === null) {
        console.log('Nothing clicked');
        return;
    }
    if (orbManager.removeOrb(objectToDelete.parent.uuid, sceneManager.getScene())) { }
    else {
    }
    scene.remove(objectToDelete);
    // Dispose of any associated resources (e.g., geometry, material)
    if ('geometry' in objectToDelete) {
        objectToDelete.geometry.dispose();
    }
    if ('material' in objectToDelete) {
        const material = objectToDelete.material;
        if (material instanceof THREE.Material) {
            material.dispose();
        }
    }
    // Optionally, dispose of any other resources specific to your application
    // Remove any references to the object
    // (This step may not be necessary in all cases, depending on your application's logic)
    (_a = objectToDelete.parent) === null || _a === void 0 ? void 0 : _a.remove(objectToDelete);
}
function clickEventControls() {
    document.addEventListener('click', function (event) {
        if (controls.isLocked === false) { //Need to rename this variable
            return;
        }
        let abilityName = AbilityMode[clickMode];
        console.log(abilityName + '(' + clickMode + ')');
        // Remove existing sprite if it exists
        //if (textSprite) {
        //    scene.remove(textSprite);
        //}
        // Create new text sprite and add to scene
        //let textSprite = TextCreation.createTextSprite(abilityName + ' (' + clickMode + ')', 1 );
        //textSprite.position.copy(camera.position); // Position in front of the camera
        //scene.add(textSprite);
        switch (clickMode) {
            case AbilityMode.CreateOrb:
                const bSphere = OrbTypes.BLACKHOLE;
                const blackHoleSphere = ObjectCreation.createOrb(bSphere.radius, bSphere.scaleFactor, camera.position.clone(), bSphere.colors);
                scene.add(blackHoleSphere);
                /*
                ObjectCreation.createOrb(
                  bSphere.radius,
                  bSphere.scaleFactor,
                  camera.position,
                  bSphere.colors
                ).forEach(bundledObject => {
                  scene.add(bundledObject);
                });
                */
                break;
            case AbilityMode.ShootProjectile:
                shootEvent();
                break;
            case AbilityMode.DeleteObject:
                deleteSelectedObject();
                break;
            case AbilityMode.CreateRay:
                break;
            case AbilityMode.InspectObject:
                setSelectedObject();
                applyOutlineToObject(currentObject);
                //displayObjectJson();         
                break;
            case AbilityMode.EnterOrb:
                setSelectedObject();
                applyOutlineToObject(currentObject);
                break;
            case AbilityMode.GenerateSphericalArrangement:
                const sphereConfig = OrbTypes.SPHERE_ORB;
                ObjectCreation.createOrbSphericalArrangement(1000, 800, camera.position.clone(), ObjectCreation.createOrb(sphereConfig.radius, sphereConfig.scaleFactor, new THREE.Vector3(), sphereConfig.colors, TextCreation.createTextSprite("∞", 2, undefined, undefined, undefined))).forEach(orb => {
                    scene.add(orb);
                });
                break;
        }
    });
}
function updateObjectCountDisplay() {
    // Get a reference to the object count element
    const objectCountElement = document.getElementById('objectCount');
    const objectCount = scene.children.length;
    objectCountElement.textContent = `Objects in scene: ${objectCount}`;
}
function updateFPSDisplay() {
    const fpsElement = document.getElementById('fpsDisplay');
    if (!fpsElement) {
        console.warn('FPS display element not found.');
        return;
    }
    const fps = Math.round(1000 / parseFloat(stats.dom.children[0].textContent.trim().split(/\s+/)[1])); // Extract FPS from Stats.js UI
    fpsElement.textContent = `FPS: ${fps}`;
}
let lastFrameTime = performance.now();
let frameCount = 0;
let fps = 0;
function updateFPSDisplayManual() {
    const fpsElement = document.getElementById('fpsDisplay');
    if (!fpsElement)
        return;
    const now = performance.now();
    frameCount++;
    if (now - lastFrameTime >= 1000) {
        fps = frameCount;
        frameCount = 0;
        lastFrameTime = now;
    }
    fpsElement.textContent = `FPS: ${fps}`;
}
//todo move
/*
function shootEvent(): void
{
  let projectile: Projectile = new Projectile(
    camera.position,
    camera.getWorldDirection(new THREE.Vector3()),
    ballSpeed,
    ballSize,
    0x5B1FDE
  );
  
  projectiles.push(projectile);
  scene.add(projectile);
}
  */
function shootEvent() {
    const currentPosition = new THREE.Vector3();
    camera.getWorldPosition(currentPosition);
    const direction = camera.getWorldDirection(new THREE.Vector3());
    const classicConfig = OrbTypes.CLASSIC;
    //TODO turn into a real orb
    let shootOrb = ObjectCreation.createOrb(classicConfig.radius, classicConfig.scaleFactor, currentPosition.clone(), // Ensure fully independent position,
    classicConfig.colors, TextCreation.createTextSprite("Orb Projectile", 1, undefined, undefined, undefined));
    let projectile = new Projectile(shootOrb, currentPosition, direction, ballSpeed);
    projectiles.push(projectile);
    scene.add(shootOrb);
}
//todo fix
function moveControls() {
    document.addEventListener('keydown', function (event) {
        // Only toggle chat window if not already open
        if (!chat.isOpenWindow()) {
            if (event.code === 'Slash' || event.code === 'KeyT') {
                // Open the chat window with appropriate input
                chat.open();
                chat.inputText = event.code === 'Slash' ? '/' : '';
                event.preventDefault();
                return;
            }
        }
        // Handle additional '/' when chat is open and Slash is pressed
        if (chat.isOpenWindow()) {
            if (event.code === 'Slash') {
                chat.inputText += '/';
                event.preventDefault();
                return;
            }
            // Close chat with Escape key without affecting other app elements
            if (event.code === 'Backquote') {
                chat.close();
                event.stopImmediatePropagation();
                event.preventDefault();
                return;
            }
            // When chat is open, ignore other key events to allow free typing
            return;
        }
        // Chat is closed: handle other movement controls
        switch (event.code) {
            case 'KeyW':
                moveForward = true;
                break;
            case 'KeyA':
                moveLeft = true;
                break;
            case 'KeyS':
                moveBackward = true;
                break;
            case 'KeyD':
                moveRight = true;
                break;
            case 'Space':
                moveUp = true;
                break;
            case 'ShiftLeft':
                moveDown = true;
                break;
            case 'KeyC':
                hyperspeed = !hyperspeed;
                break;
        }
    });
    document.addEventListener('keyup', function (event) {
        // If chat is open, skip movement control handling
        if (chat.isOpenWindow())
            return;
        // Handle movement control release
        switch (event.code) {
            case 'KeyW':
                moveForward = false;
                break;
            case 'KeyA':
                moveLeft = false;
                break;
            case 'KeyS':
                moveBackward = false;
                break;
            case 'KeyD':
                moveRight = false;
                break;
            case 'Space':
                moveUp = false;
                break;
            case 'ShiftLeft':
                moveDown = false;
                break;
        }
    });
}
function hyperspeedSwitch() {
    if (hyperspeed) {
        movementDrag = 4;
        movementSpeed = 800.0;
    }
    else {
        movementDrag = 8;
        movementSpeed = 16.0;
    }
}
function onWindowResize() {
    window.addEventListener('resize', function () {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
        // Update composer size
        composer.setSize(window.innerWidth, window.innerHeight);
    });
}
function animate() {
    stats.begin();
    requestAnimationFrame(animate);
    render();
    update();
    stats.end();
}
function render() {
    // Render the scene normally
    //renderer.render(scene, camera);
    // Render the outline pass on top of the scene
    composer.render();
}
function update() {
    const time = performance.now();
    if (controls.isLocked === true) {
        hyperspeedSwitch();
        const delta = (time - prevTime) / 200;
        //Deacceleration 
        velocity.x -= velocity.x * movementDrag * delta;
        velocity.z -= velocity.z * movementDrag * delta;
        velocity.y -= velocity.y * movementDrag * delta;
        //Movement switches
        movementInputDirection.z = Number(moveForward) - Number(moveBackward);
        movementInputDirection.x = Number(moveRight) - Number(moveLeft);
        movementInputDirection.y = Number(moveDown) - Number(moveUp);
        movementInputDirection.normalize(); // this ensures consistent movements in all directions      
        if (moveForward || moveBackward)
            velocity.z -= movementInputDirection.z * movementSpeed * delta;
        if (moveLeft || moveRight)
            velocity.x -= movementInputDirection.x * movementSpeed * delta;
        if (moveUp || moveDown)
            velocity.y -= movementInputDirection.y * movementSpeed * delta;
        controls.moveRight(-velocity.x * delta);
        controls.moveForward(-velocity.z * delta);
        controls.getObject().position.y += (velocity.y * delta); // new behavior    
        if (doWorldFloor == true && (controls.getObject().position.y < 1.5)) {
            controls.getObject().position.y = 1.5;
        }
        orbManager.updateOrbMotion();
        projectiles.forEach((e) => e.updatePosition());
        //updateProjectiles();
        //Stats
        stats.update();
        // Update FPS display
        updateFPSDisplayManual();
        // Optionally update object count if needed
        updateObjectCountDisplay();
    }
    prevTime = time;
}
init;
init();
animate();
