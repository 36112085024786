import * as THREE from 'three';
import { OrbManager } from './OrbManager';
export class Orb {
    constructor(styleConfig = null, jsonData = {}) {
        this.group = null; // Lazy-loaded group
        this.inScene = false; // ✅ Tracks whether it's added to the scene
        this.styleConfig = styleConfig;
        this.jsonData = jsonData;
        this.dependentOrbs = new Set();
        this.dependencyOrbs = new Set();
        this.velocity = new THREE.Vector3(0, 0, 0);
        this.text = (styleConfig === null || styleConfig === void 0 ? void 0 : styleConfig.text) || null;
    }
    /** Lazily creates and returns the THREE.Group */
    createOrb() {
        if (!this.styleConfig) {
            throw new Error("Orb cannot be created without a style configuration.");
        }
        if (this.group) {
            return this.group; // Prevent redundant creation
        }
        const { radius, scaleFactor, colors, text } = this.styleConfig; //todo might change how text comes in and is created, right now it comes as a whole sprite
        //todo pull this creation stuff out into it's own function because a lot more will be added
        const group = new THREE.Group();
        const sphereGeometry = new THREE.SphereGeometry(radius, 72, 64).toNonIndexed();
        group.uuid = sphereGeometry.uuid;
        // Create outline meshes with different colors and scaled outlines
        for (let i = 0; i < colors.length; i++) {
            const scale = 1 + i * scaleFactor;
            const material = new THREE.MeshBasicMaterial({
                color: colors[i],
                side: THREE.BackSide,
            });
            // Total gerryrig here targeting blackhole orb, will be refactored into IStyleConfig (todo)
            if (this.styleConfig.radius == 200 && i == 0) {
                material.side = THREE.FrontSide;
            }
            const outline = new THREE.Mesh(sphereGeometry, material);
            outline.scale.setScalar(scale);
            group.add(outline);
        }
        // Add text sprite if provided
        if (text) {
            //todo find a way to have these text sprites always render above/in front of their respective orb
            //this would enable use to use the front side of the orb material and make them look more solid
            group.add(text);
        }
        this.group = group;
        return group;
    }
    /** ✅ Add this Orb to a Scene through OrbManager */
    addToScene(scene) {
        if (!this.group)
            this.createOrb();
        if (!this.inScene) {
            OrbManager.getInstance().addOrb(this, scene);
            this.inScene = true;
        }
    }
    /** ✅ Remove this Orb from the Scene */
    removeFromScene(scene) {
        if (this.inScene) {
            OrbManager.getInstance().removeOrb(this.group.uuid, scene);
            this.inScene = false;
        }
    }
    /** Set the entire orb group's position. */
    setPosition(position) {
        if (!this.group)
            this.createOrb();
        this.group.position.copy(position);
    }
    /** Update the displayed text (removes old sprite, attaches new). */
    updateText(newText) {
        if (!this.group)
            this.createOrb();
        if (this.text) {
            this.group.remove(this.text);
        }
        this.text = newText;
        this.group.add(newText);
    }
    /** Add a dependency relationship. */
    addDependency(orbId) {
        this.dependencyOrbs.add(orbId);
    }
    /** Add a dependent relationship. */
    addDependent(orbId) {
        this.dependentOrbs.add(orbId);
    }
    /** Display JSON data to the console. */
    displayJson() {
        let jsonData = JSON.stringify(this.jsonData, null, 2);
        console.log(jsonData);
        return jsonData;
    }
    /** Apply a transformation function to the position. */
    // I am sus of this, I don't want to check if the group exists every frame but I don't know how it could be safely avoided without likely separating somethign out
    applyTransformation(func) {
        if (!this.group)
            this.createOrb();
        this.group.position.copy(func(this.group.position));
    }
    /** Update position based on velocity. */
    updatePosition() {
        if (this.velocity.lengthSq() < Math.pow(Orb.VELOCITY_EPSILON, 2)) {
            this.velocity.set(0, 0, 0);
            return;
        }
        if (!this.group)
            this.createOrb();
        this.group.position.add(this.velocity);
        if (this.group.position.y <= 0) {
            this.velocity.reflect(new THREE.Vector3(0, 1, 0));
        }
        this.velocity.multiplyScalar(0.999); // Apply drag
    }
    /** Add velocity to the orb. */
    addVelocity(velocity) {
        this.velocity.add(velocity);
    }
}
Orb.VELOCITY_EPSILON = 1e-6;
