export class OrbManager {
    constructor() {
        this.orbs = new Map(); // Map for easy CRUD by UUID
        this.orbArray = []; // ✅ Fast iterable array copy of `orbs.values()`
    }
    // could two threads every do this at the same time and create two managers?
    static getInstance() {
        if (!OrbManager.instance) {
            OrbManager.instance = new OrbManager();
        }
        return OrbManager.instance;
    }
    /** **Add an orb to the scene and track it** */
    addOrb(orb, scene) {
        const group = orb.createOrb();
        if (!group) {
            console.warn('Orb creation failed.');
            return;
        }
        scene.add(group);
        this.orbs.set(group.uuid, orb);
        this.orbArray.push(orb); // ✅ Keep `orbArray` in sync
    }
    /** **Find an orb by its UUID** */
    getOrb(uuid) {
        return this.orbs.get(uuid);
    }
    /** **Remove an orb from the scene and tracking** */
    removeOrb(uuid, scene) {
        const orb = this.orbs.get(uuid);
        if (!orb)
            return false;
        //in the future perhaps we check if it exists in the scene first
        scene.remove(orb.group);
        this.orbs.delete(uuid);
        // ✅ Keep `orbArray` in sync by filtering it
        this.orbArray = this.orbArray.filter(o => o.group.uuid !== uuid);
        return true;
    }
    //TODO review how necessary this is
    /**
     * Assign a position to each orb before adding them to the scene (lazy positioning).
     * You can randomize or systematically place orbs here.
     */
    positionOrbs(orbList, getPosition) {
        orbList.forEach((orb, i) => {
            const pos = getPosition(i);
            orb.setPosition(pos);
        });
    }
    /** **✅ Fast Orb Update Every Frame using a plain for-loop** */
    updateOrbMotion() {
        const len = this.orbArray.length;
        for (let i = 0; i < len; i++) {
            this.orbArray[i].updatePosition();
        }
    }
    /**
     * Handle an orb click from a three.js raycast intersection.
     */
    handleOrbClick(clickedObject) {
        const parentGroup = clickedObject.parent;
        if (!parentGroup) {
            console.log('No parent group found for clicked object.');
            return;
        }
        const orb = this.getOrb(parentGroup.uuid);
        if (orb) {
            console.log('Orb clicked:', parentGroup.uuid);
            return orb.displayJson();
        }
        else {
            console.log('No orb found for UUID:', parentGroup.uuid);
            return "No orb found";
        }
    }
}
