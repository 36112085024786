var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios, { AxiosError } from 'axios';
// Base API URL
const API_BASE_URL = 'https://api.orbscape.io';
/**
 * Utility function to handle API errors consistently.
 * Logs error details for debugging purposes.
 * @param error - Error object thrown by Axios
 */
function handleApiError(error) {
    var _a, _b;
    if (error instanceof AxiosError) {
        console.error('API Error:', {
            message: error.message,
            responseData: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data,
            status: (_b = error.response) === null || _b === void 0 ? void 0 : _b.status,
            config: error.config,
        });
    }
    else {
        console.error('Unexpected Error:', error);
    }
}
/**
 * Fetch language orbs (formerly `fetchEntries`).
 * @returns Promise resolving to language orb data.
 */
export function getLanguageOrbs() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/functions`);
            return response.data;
        }
        catch (error) {
            handleApiError(error);
            throw error; // Re-throw for upstream handling
        }
    });
}
/**
 * Fetch GitHub repository data.
 * @returns Promise resolving to GitHub repository data.
 */
export function getGitHubRepos() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/systemrepositories`);
            return response.data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Fetch Docker container data.
 * @returns Promise resolving to Docker container data.
 */
export function getDockerContainers() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/dockercontainers`);
            return response.data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Fetch Kubernetes pod information.
 * @returns Promise resolving to Kubernetes pod data.
 */
export function getKubernetesPods() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/kubernetespods`);
            return response.data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Fetch Kubernetes node information.
 * @returns Promise resolving to Kubernetes node data.
 */
export function getKubernetesNodes() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/kubernetesnodes`);
            return response.data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Fetch Desmos graphs.
 * @returns Promise resolving to Desmos data.
 */
export function getDesmosOrbs() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/desmos`);
            // Log the full response object
            //console.log("Desmos API Full Response:", response);
            // Ensure the response has the expected structure
            if (!(response === null || response === void 0 ? void 0 : response.data) || !Array.isArray(response.data.data)) {
                console.error("Desmos API Error: Expected an array at response.data.data but got:", response.data);
                return [];
            }
            //console.log("Desmos API Extracted Data:", response.data.data);
            return response.data.data; // Correct extraction of the array
        }
        catch (error) {
            handleApiError(error);
            return []; // Prevent breaking execution
        }
    });
}
export function getContainerEndpoints() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/endpoints`);
            // Log the full response object
            console.log("Endpoints API Full Response:", response);
            return response.data.output;
        }
        catch (error) {
            handleApiError(error);
            return []; // Prevent breaking execution
        }
    });
}
/**
 * Get all text entries from the text store.
 * @returns Promise resolving to text entries.
 */
export function getAllTextEntries() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/GetTextStore`);
            return response.data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Add a new text entry to the text store.
 * @param text - Text content to add.
 * @returns Promise resolving to the created text entry.
 */
export function addText(text) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield axios.post(`${API_BASE_URL}/AddTextStore`, { text });
            return data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Update an existing text entry in the text store.
 * @param id - ID of the text entry to update.
 * @param text - New text content.
 * @returns Promise resolving to the updated text entry.
 */
export function updateText(id, text) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield axios.put(`${API_BASE_URL}/UpdateTextStore/${id}`, { text });
            return data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
/**
 * Delete a text entry from the text store.
 * @param id - ID of the text entry to delete.
 * @returns Promise resolving to the deleted text confirmation.
 */
export function deleteText(id) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield axios.delete(`${API_BASE_URL}/DeleteTextStore/${id}`);
            return data;
        }
        catch (error) {
            handleApiError(error);
            throw error;
        }
    });
}
