// Define all known orb style000s
const languageOrbSizes = 100;
const orbConfigs = {
    BLACKHOLE: {
        radius: 200,
        scaleFactor: 0.025,
        colors: [0x000000, 0x3333cc, 0x6666e6, 0x9999ff, 0xb3bdff, 0xccd9ff, 0xe6e6ff, 0xfafafa, 0xffffff],
    },
    BLACKHOLE_ALT: {
        radius: 100,
        scaleFactor: 0.025,
        colors: [0x27ccbb, 0x2d6af7, 0x7F87F8, 0xbabfff, 0xd7d9f7],
    },
    CLASSIC: {
        radius: languageOrbSizes,
        scaleFactor: 0.05,
        colors: [0x27ccbb, 0x7F87F8],
    },
    TEST_NEST: {
        radius: 40,
        scaleFactor: 0.05,
        colors: [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff, 0xffffff]
    },
    RAINBOW_NEST: {
        radius: 50,
        scaleFactor: 0.2,
        colors: [
            0xff0000,
            0xff2000,
            0xff4000,
            0xff6000,
            0xff8000,
            0xffa000,
            0xffbf00,
            0xffdf00,
            0xffff00,
            0xdfff00,
            0xbfff00,
            0x9fff00,
            0x80ff00,
            0x60ff20,
            0x40ff40,
            0x20ff60,
            0x00ff80,
            0x00ffa0,
            0x00ffbf,
            0x00ffdf,
            0x00ffff,
            0x00dfff,
            0x00bfff,
            0x009fff,
            0x0080ff,
            0x0060ff,
            0x0040ff,
            0x4020ff,
            0x8000ff,
            0xbf00ff,
            0xdf00ff,
            0xff00ff,
            0xff00df,
            0xff00bf,
            0xff009f,
            0xff0080,
            0xff0060,
            0xff0040,
            0xff2020,
            0xff0000 // Red (closes the loop)
        ]
    },
    TEST_ORB: {
        radius: 5,
        scaleFactor: 1.5,
        colors: [0x000000, 0x444444, 0x888888],
    },
    TEST_PROJECTILE: {
        radius: 4,
        scaleFactor: 0.8,
        colors: [0x333333, 0xFFFFFF],
    },
    API_ORB: {
        radius: 40,
        scaleFactor: 0.08,
        colors: [0x27ccbb, 0x7F87F8],
    },
    SPHERE_ORB: {
        radius: 15,
        scaleFactor: 0.08,
        colors: [0x27ccbb, 0x7F87F8],
    },
    //Code language orbs
    BASH_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x89e051, 0xf0f0f0], // Bash green center, light gray outline
    },
    JAVA_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xf49518, 0x04748c], // Java brown center, light gray outline
    },
    PYTHON_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x32d190, 0x5FB43A], // Python blue center, light gray outline
    },
    POWERSHELL_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x477CDA, 0x2AAACD], // PowerShell dark blue center, light gray outline
    },
    GO_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x00ADD8, 0xf0f0f0], // Go cyan center, light gray outline
    },
    RUST_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xDD3515, 0xEF4A00], // Rust orange center, light gray outline
    },
    JAVASCRIPT_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x3178C6, 0xf0f0f0],
    },
    TYPESCRIPT_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x3178C6, 0xf0f0f0], // TypeScript blue center, light gray outline
    },
    ANSIBLE_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xCC0000, 0xf0f0f0],
    },
    // Other important types
    MATH_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xdee0ff, 0xf0f0f0],
    },
    DESMOS_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x0BA020, 0xf0f0f0],
    },
    GITHUB_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xf04c34, 0xf0f0f0] //[0x7C33A4, 0xf04c34],
    },
    DOCKER_CONTAINER_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x1D63ED, 0xf0f0f0],
    },
    KUBERNETES_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xFCC41C, 0x3069de],
    },
    CONTAINER_ENDPOINT_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x84EC2C, 0x173647],
    },
    COMPUTER_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x2d3ec3, 0xc1c6d3],
    },
    PROMPT_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x18ed86, 0xf0f0f0],
    },
    PROMPT_RESPONSE_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xFF00FF, 0xf0f0f0],
    },
    AI_AGENT_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x2596BE, 0xf0f0f0],
    },
    SYSTEM_WILL_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0xf5ff00, 0xfab500, 0xf1840b],
    },
    TEXT_INFO_ORB: {
        radius: languageOrbSizes,
        scaleFactor: 0.08,
        colors: [0x27ccbb, 0x7F87F8],
    }
};
/**
 * **Dynamically Generated Enum-like Object for Known Orb Types**
 * - Always stays in sync with `orbConfigs`
 */
//export const OrbTypes = Object.freeze(
//    Object.fromEntries(Object.keys(orbConfigs).map((key) => [key, key]))
//);
/**
 * **Merged Enum-like Object that holds both names and their config**
 * - Supports **dot notation** (`OrbTypes.PYTHON_ORB`)
 * - Supports **dynamic access** (`OrbTypes[orbType]`)
 */
//export const OrbTypes = Object.freeze(
//    Object.entries(orbConfigs).reduce((acc, [key, value]) => {
//        acc[key] = key;  // Store type name (for dot notation)
//        acc[key + '_CONFIG'] = value; // Store corresponding config
//        return acc;
//    }, {} as Record<string, string | IOrbStyleConfig>)
//);
/**
 * **Single Export for Orb Types & Configs**
 * - `OrbTypes.PYTHON_ORB` → `"PYTHON_ORB"` (string)
 * - `OrbTypes.PYTHON_ORB_CONFIG` → `{ radius: ..., scaleFactor: ..., colors: [...] }` (object)
 * - **Directly access configurations dynamically!** `OrbTypes[orbType]`
 * AI generated method of doing this, there might be a better way.
 * Usage: const config = OrbTypes[orbType] as IOrbStyleConfig;
 */
//export const OrbTypes: Record<string, string | IOrbStyleConfig> = Object.freeze({
//    ...Object.fromEntries(Object.keys(orbConfigs).map(key => [key, key])),
//    ...orbConfigs, // Store actual config inside the same object
//});
/**
 * `OrbTypes` is now a **directly mapped reference** to `orbConfigs`
 * - This allows both dot notation (`OrbTypes.RAINBOW_NEST`) and dynamic access (`OrbTypes[orbType]`).
 */
export const OrbTypes = Object.freeze(orbConfigs);
export default { OrbTypes };
